import i18n from './translations';
import { usePurchaseStore } from './stores/purchase';
import { useUserStore } from './stores/user';

const filters = {
    currency(price: number, withExchange = true) {
        const { getCurrentExchange } = usePurchaseStore();

        if (isNaN(price) || !getCurrentExchange) {
            return '-';
        }
        const rate = withExchange ? getCurrentExchange.rate : 1;
        return `${getCurrentExchange.symbol}${(price / rate / 100).toFixed(2)}`;
    },
    shortCurrency(price: number, withExchange = true) {
        const { getCurrentExchange } = usePurchaseStore();

        if (isNaN(price) || !getCurrentExchange) {
            return '-';
        }
        const rate = withExchange ? getCurrentExchange.rate : 1;
        return `${getCurrentExchange.symbol}${Math.ceil(price / rate / 100)}`;
    },
    exchangeAmount(price: number) {
        const { getCurrentExchange } = usePurchaseStore();
        if (isNaN(price) || !getCurrentExchange) {
            throw `Incorrect amount price`;
        }
        return Math.ceil(price / getCurrentExchange.rate / 100) * 100; //Use the right amount not the white amount
    },
    bonusPercentage(amount: number) {
        let cc = Math.floor(amount);
        return `+${cc.toFixed(0)}%`;
    },
    niceCredits(credits: number) {
        let cc = Math.floor(credits);
        return cc.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    date(value: number) {
        const date = new Date(value * 1000);

        return formatDate(date);
    },
    shortDate(value: number) {
        const date = new Date(value * 1000);
        return formatShortDate(date);
    },
    truncate(text: string, length: number, suffix: any) {
        if (text.length > length) {
            return text.substring(0, length) + suffix;
        } else {
            return text;
        }
    },
    countdown(seconds: number) {
        const set = { day: 86400, hour: 3600, min: 60 };

        const days = seconds / set.day;
        const hours = seconds / set.hour;
        const minutes = seconds / set.min;

        const total = days <= 1 ? (hours <= 1 ? Math.round(minutes) : Math.round(hours)) : Math.round(days);
        const totalLabel = days <= 1 ? (hours <= 1 ? i18n.global.t('profile.minutes') : i18n.global.t('profile.hours')) : i18n.global.t('profile.days');
        return `${total} ${totalLabel}`;
    }
};

export default filters;

const leadingZero = (num: number) => `0${num}`.slice(-2);

function formatDate(date: Date): string {
    return `${leadingZero(date.getDate())}-${leadingZero(date.getMonth() + 1)}-${date.getFullYear()} | ${leadingZero(date.getHours())}:${leadingZero(date.getMinutes())}`;
}

function formatShortDate(date: Date): string {
    return `${leadingZero(date.getDate())}-${leadingZero(date.getMonth() + 1)}-${date.getFullYear()}`;
}
